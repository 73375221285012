export default {
  data() {
    return {
      // elmentList: [
      //   {
      //     pcElementId: 408,
      //     phoneElementId: 414,
      //     descList: [
      //       {
      //         descName: "防爆轮式机器人- SGLS-C3",
      //         sort: 1,
      //       },
      //       {
      //         descName: "Ex db IIC T6 Gb",
      //         sort: 2,
      //       },
      //       {
      //         descName: "IP66",
      //         sort: 3,
      //       },
      //       {
      //         descName: "0-1.4m/s",
      //         sort: 4,
      //       },
      //       {
      //         descName: "≤5h",
      //         sort: 5,
      //       },
      //       {
      //         descName: "防爆等级",
      //         sort: 6,
      //       },
      //       {
      //         descName: "防护等级",
      //         sort: 7,
      //       },
      //       {
      //         descName: "行走速度",
      //         sort: 8,
      //       },
      //       {
      //         descName: "工况续航",
      //         sort: 9,
      //       },
      //     ],
      //     type: "image",
      //     sort: 1,
      //   },
      //   {
      //     pcElementId: 409,
      //     phoneElementId: 415,
      //     descList: [
      //       {
      //         descName: "防爆轮式机器人-SGLS-C3",
      //         sort: 1,
      //       },
      //       {
      //         descName:
      //           "采用模块化设计，灵活集成多种设备，可适应不同应用场景的需求，提供跑冒滴漏、设备安全、人员安全、场所安全、设备工作状态、设备异常状态等巡检功能，进一步减少安全隐患。搭载超声波全向防撞及探沟防跌落系统，增加厂区设备及人员安全保障，可加装AI工控机，实现毫秒级识别响应，多维性能升级助力巡检更加安全可靠。",
      //         sort: 2,
      //       },
      //     ],
      //     type: "image",
      //     sort: 2,
      //   },
      //   {
      //     pcElementId: 410,
      //     phoneElementId: 416,
      //     descList: [
      //       {
      //         descName: "应用场景",
      //         sort: 1,
      //       },
      //       {
      //         descName:
      //           "防爆轮式机器人-SGLS-C3可为油气开采业、管道运输业、油煤燃料加工业、燃气产供业、石油化学品制造业、化学纤维制造业、发电业、输配供电业等广大企业厂矿场站提供基于安全生产的智能巡检方案。适用于各类油、气、田、管、罐、线、缆等易燃易爆、有毒有害的室外及室内智能巡检业务场景。",
      //         sort: 2,
      //       },
      //     ],
      //     type: "image",
      //     sort: 3,
      //   },
      //   {
      //     pcElementId: "",
      //     phoneElementId: 417,
      //     descList: [
      //       {
      //         descName: "基本功能",
      //         sort: 1,
      //       },
      //       {
      //         descName: "遥控器控制",
      //         sort: 2,
      //       },
      //       {
      //         descName: "急停功能",
      //         sort: 3,
      //       },
      //       {
      //         descName: "防撞功能",
      //         sort: 4,
      //       },
      //       {
      //         descName: "无人自主巡检",
      //         sort: 5,
      //       },
      //       {
      //         descName: "双向语音",
      //         sort: 6,
      //       },
      //       {
      //         descName: "智能避障功能",
      //         sort: 7,
      //       },
      //       {
      //         descName: "补光灯功能",
      //         sort: 8,
      //       },
      //       {
      //         descName: "智能充电功能",
      //         sort: 9,
      //       },
      //       {
      //         descName: "充电保护功能",
      //         sort: 10,
      //       },
      //       {
      //         descName: "网络通讯、远程控制",
      //         sort: 11,
      //       },
      //       {
      //         descName: "定点巡检功能",
      //         sort: 12,
      //       },
      //       {
      //         descName: "环境气体监测",
      //         sort: 13,
      //       },
      //       {
      //         descName: "视频存储监控",
      //         sort: 14,
      //       },
      //       {
      //         descName: "语音播报",
      //         sort: 15,
      //       },
      //       {
      //         descName: "超声波雷达",
      //         sort: 16,
      //       },
      //       {
      //         descName: "远程功能",
      //         sort: 17,
      //       },
      //       {
      //         descName: "可视界面",
      //         sort: 18,
      //       },
      //       {
      //         descName: "离线巡检",
      //         sort: 19,
      //       },
      //     ],
      //     type: "image",
      //     sort: 4,
      //   },
      //   {
      //     pcElementId: 411,
      //     phoneElementId: 417,
      //     descList: [
      //       {
      //         descName: "选配功能",
      //         sort: 1,
      //       },
      //       {
      //         descName: "自动控制充电房",
      //         sort: 2,
      //       },
      //       {
      //         descName: "甲烷激光遥测",
      //         sort: 3,
      //       },
      //       {
      //         descName: "火焰探测功能",
      //         sort: 4,
      //       },
      //       {
      //         descName: "气象环境采集功能",
      //         sort: 5,
      //       },
      //       {
      //         descName: "实时巡检",
      //         sort: 6,
      //       },
      //     ],
      //     type: "image",
      //     sort: 5,
      //   },

      //   {
      //     pcElementId: 412,
      //     phoneElementId: 417,
      //     descList: [
      //       {
      //         descName: "防爆轮式机器人-SGLS-C3",
      //         sort: 0,
      //       },
      //       {
      //         descName: "规格参数",
      //         sort: 1,
      //       },
      //       {
      //         descName: "外形尺寸(长×宽×高)",
      //         sort: 2,
      //       },
      //       {
      //         descName: "防爆等级",
      //         sort: 3,
      //       },
      //       {
      //         descName: "驱动方式",
      //         sort: 4,
      //       },
      //       {
      //         descName: "爬坡能力",
      //         sort: 5,
      //       },
      //       {
      //         descName: "越障能力",
      //         sort: 6,
      //       },
      //       {
      //         descName: "主导航方式",
      //         sort: 7,
      //       },
      //       {
      //         descName: "整备质量",
      //         sort: 8,
      //       },
      //       {
      //         descName: "防护等级",
      //         sort: 9,
      //       },
      //       {
      //         descName: "行走速度",
      //         sort: 10,
      //       },
      //       {
      //         descName: "越障能力",
      //         sort: 11,
      //       },
      //       {
      //         descName: "工况续航",
      //         sort: 12,
      //       },
      //       {
      //         descName: "重复导航定位精度",
      //         sort: 13,
      //       },
      //       {
      //         descName: "≤1250mm×960mm×920mm",
      //         sort: 14,
      //       },
      //       {
      //         descName: "Ex db IIC T6 Gb",
      //         sort: 15,
      //       },
      //       {
      //         descName: "四轮四驱，差速转向",
      //         sort: 16,
      //       },
      //       {
      //         descName: "≥25",
      //         sort: 17,
      //       },
      //       {
      //         descName: "≥120mm",
      //         sort: 18,
      //       },
      //       {
      //         descName: "SLAM激光导航和SLAM激光定位",
      //         sort: 19,
      //       },
      //       {
      //         descName: "≤450kg",
      //         sort: 20,
      //       },
      //       {
      //         descName: "IP66",
      //         sort: 21,
      //       },
      //       {
      //         descName: "0-1.4m/s，速度可调",
      //         sort: 22,
      //       },
      //       {
      //         descName: "≤100mm",
      //         sort: 23,
      //       },
      //       {
      //         descName: "≤5h",
      //         sort: 24,
      //       },
      //       {
      //         descName: "≤±3cm",
      //         sort: 25,
      //       },
      //     ],
      //     type: "image",
      //     sort: 6,
      //   },
      //   {
      //     pcElementId: 413,
      //     phoneElementId: 418,
      //     descList: [
      //       {
      //         descName: "防爆轮式机器人-SGLS-C3",
      //         sort: 1,
      //       },
      //       {
      //         descName: "深入危险区域，提升巡检智能化水平",
      //         sort: 2,
      //       },
      //     ],
      //     type: "image",
      //     sort: 7,
      //   },
      // ],

      elmentList: [],
      aboutswiper: null,
    };
  },
  mounted() {
    this.$store.dispatch("pageset/getbannerIndex", 0);
    document.documentElement.style.setProperty("--var-primary-color", "#fff");
    // this.$store.dispatch("pageset/getSourceData", {
    //   createBy: "",
    //   updateBy: "",
    //   elmentList: this.elmentList,
    //   pageId: this.$route.query.pageId, //页面id
    //   pageName: this.$route.path,
    //   path: this.$route.path,
    //   remark: "",
    // });
    // 获取当前页面的配置信息
    this.$store
      .dispatch("pageset/getPageInfo", this.$route.query.pageId)
      .then(() => {
        this.elmentList = this.$store.state.pageset.sourceData.elmentList;
      });
  },
  methods: {
    retention() {
      this.$router.push("/reservation");
    },
    openPdf() {
      this.$router.push("/pdfView");
    },
  },
};
